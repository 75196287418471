module.exports = [{
      plugin: require('/usr/src/app/www/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1478352-16"},
    },{
      plugin: require('/usr/src/app/www/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/usr/src/app/www/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
